



















































































import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Page from '@/components/Page/index.vue'
import Status from '@/utils/Status'
import api from '../../api'
import ItemSimple from '../../components/ItemSimple/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'

@Component({ components: { Page, StatusFlash, ItemSimple } })
export default class Shapes extends Vue {
  status: Status = new Status()

  shapes: any = null

  created() {
    this.fetchData()
  }

  @Watch('$route')
  async fetchData() {
    try {
      this.status.setPending()
      const response = await api.shapes.getShapes()
      this.shapes = _.orderBy(response.data, ['name'], ['asc'])
      this.status.setDone()
    } catch (error) {
      this.status.setError('Unable to get shapes.')
    }
  }

  async deleteShape(shape) {
    if (window.confirm(`Are you sure you want to delete ${shape.name}?`)) {
      try {
        await api.shapes.deleteShape(shape)
        this.fetchData()
      } catch (error) {
        this.status.setError('Unable to delete shape')
      }
    }
  }
}
