






































































































































































import _ from 'lodash'
import { required } from 'vuelidate/lib/validators'
import api from '@/api'
import Status from '@/utils/Status'
import Page from '../../components/Page/index.vue'
import StatusFlash from '../../components/StatusFlash/index.vue'

export default {
  name: 'IndexSettings',
  components: {
    Page,
    StatusFlash,
  },

  validations() {
    return {
      settings: {
        retrieval: {
          rateLimitWait: { required },
          timeout: { required },
        },
        ping: {
          validDuration: { required },
          rateLimitDuration: { required },
          rateLimitHits: { required },
          denyList: {
            $each: { item: {} },
          },
        },
      },
    }
  },

  data() {
    return {
      status: new Status(),
      submitStatus: new Status(),
      settings: null,
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()
        const response = await api.fdpIndex.getSettings()
        this.settings = this.requestDataToFormData(response.data)
        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get settings')
      }
    },

    async submitSettings() {
      this.$v.settings.$touch()

      if (!this.$v.settings.$invalid) {
        try {
          this.sanitizeDenyList()
          this.submitStatus.setPending()
          const response = await api.fdpIndex.putSettings(this.fromDataToRequestData(this.settings))
          this.settings = this.requestDataToFormData(response.data)
          this.submitStatus.setDone('Settings were successfully updated!')
        } catch (error) {
          this.submitStatus.setError('Settings could not be updated.')
        }
      }
    },

    async resetToDefaults() {
      if (window.confirm('Are you sure you want to reset settings to defaults?')) {
        try {
          this.submitStatus.setPending()
          const response = await api.fdpIndex.deleteSettings()
          this.settings = this.requestDataToFormData(response.data)
          this.submitStatus.setDone('Settings were successfully reset to defaults!')
        } catch (error) {
          this.submitStatus.setError('Settings could not be reset to defaults!')
        }
      }
    },

    sanitizeDenyList() {
      const filter = i => !_.isEmpty(i.item)
      this.settings.ping.denyList = this.settings.ping.denyList.filter(filter)
    },

    fromDataToRequestData(formData) {
      const data = { ping: { ...formData.ping }, retrieval: { ...formData.retrieval } }
      data.ping.denyList = data.ping.denyList.map(i => i.item)
      return data
    },

    requestDataToFormData(requestData) {
      const formData = { ...requestData }
      formData.ping.denyList = formData.ping.denyList.map(item => ({ item }))
      return formData
    },

    addDenyLink() {
      this.settings.ping.denyList.push({ item: '' })
    },

    removeDenyLink(index) {
      this.settings.ping.denyList.splice(index, 1)
    },
  },
}
