













































































































































































































import { required, url } from 'vuelidate/lib/validators'
import api from '@/api'
import Status from '@/utils/Status'
import StatusFlash from '../../components/StatusFlash/index.vue'
import Page from '../../components/Page/index.vue'

export default {
  name: 'FdpSettings',
  components: {
    Page,
    StatusFlash,
  },

  validations() {
    return {
      settings: {
        metadataMetrics: {
          $each: {
            metricUri: { required, url },
            resourceUri: { required, url },
          },
        },
        ping: {
          enabled: { required },
          endpoints: {
            $each: {
              endpoint: { required, url },
            },
          },
        },
      },
    }
  },

  data() {
    return {
      status: new Status(),
      submitStatus: new Status(),
      settings: null,
      settingsData: null,
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      try {
        this.status.setPending()
        const response = await api.settings.get()
        this.settingsData = response.data
        this.settings = this.requestDataToFormData(response.data)
        this.status.setDone()
      } catch (error) {
        this.status.setError('Unable to get settings')
      }
    },

    addMetadataMetric() {
      this.settings.metadataMetrics.push({
        metricUri: null,
        resourceUri: null,
      })
    },

    removeMetadataMetric(index) {
      this.settings.metadataMetrics.splice(index, 1)
    },

    addPingEndpoint() {
      this.settings.ping.endpoints.push({
        endpoint: null,
      })
    },

    removePingEndpoint(index) {
      this.settings.ping.endpoints.splice(index, 1)
    },

    async submitSettings() {
      this.$v.settings.$touch()

      if (!this.$v.settings.$invalid) {
        try {
          this.submitStatus.setPending()
          const response = await api.settings.put(this.fromDataToRequestData(this.settings))
          this.settings = this.requestDataToFormData(response.data)
          this.submitStatus.setDone('Settings were successfully updated!')
        } catch (error) {
          this.submitStatus.setError('Settings could not be updated.')
        } finally {
          window.scrollTo(0, 0)
        }
      }
    },

    fromDataToRequestData(formData) {
      return {
        metadataMetrics: formData.metadataMetrics,
        ping: {
          enabled: formData.ping.enabled,
          endpoints: formData.ping.endpoints.map(e => e.endpoint),
        },
      }
    },

    requestDataToFormData(requestData) {
      const formData = { ...requestData }
      formData.ping.endpoints = formData.ping.endpoints.map(endpoint => ({ endpoint }))
      return formData
    },
  },
}
