










































































































import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Page from '@/components/Page/index.vue'
import config from '@/config'
import Status from '@/utils/Status'
import StatusFlash from '@/components/StatusFlash/index.vue'
import { stateClass } from '@/utils/fdpIndex'
import api from '../../api'
import Breadcrumbs from '../../components/Breadcrumbs/index.vue'

@Component({ components: { Breadcrumbs, Page, StatusFlash } })
export default class IndexDetail extends Vue {
  fdp: any = null

  status: Status = new Status()

  get breadcrumbs() {
    return [{
      label: 'FAIR Data Point Index',
      to: '/',
    }]
  }

  get title() {
    return _.get(this.fdp, 'clientUrl')
  }

  get metadataTitle() {
    return _.get(this.fdp, 'currentMetadata.metadata.title')
  }

  get metadataVersion() {
    return _.get(this.fdp, 'currentMetadata.metadata.version')
  }

  get metadataPublisher() {
    return _.get(this.fdp, 'currentMetadata.metadata.publisher')
  }

  get metadataPublisherName() {
    return _.get(this.fdp, 'currentMetadata.metadata.publisherName')
  }

  get metadataDescription() {
    return _.get(this.fdp, 'currentMetadata.metadata.description')
  }

  created(): void {
    this.init()
  }

  badgeClass(state) {
    return stateClass(state)
  }

  formatDateTime(value): string {
    return moment(value).format(config.dateTimeFormat)
  }

  @Watch('$route')
  async init() {
    try {
      this.status.setPending()
      const response = await api.fdpIndex.getEntry(this.$route.params.id)
      this.fdp = response.data
      this.status.setDone()
    } catch (error) {
      this.status.setError('Unable to get FAIR Data Point.')
    }
  }
}
